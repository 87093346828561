<template>
  <b-container fluid>
    <b-row>
      <b-col class="text-center mt-3">
        <b-img src="@/assets/dark1.svg"></b-img>
      </b-col>
    </b-row>
    <b-row class="justify-content-center pb-5 pt-3">
      <b-col sm="8" md="8" lg="6">
        <b-card classignupResponses="">
          <b-col class="text-center"> </b-col>
          <b-card-body
            id="ActivateOk"
            v-if="!loading && submitted && successedActivation"
            class="pt-3"
          >
            <b-row>
              <b-col class="text-center text-secondary" variant="secondary">
                <svg style="width: 92px; height: 92px" viewBox="0 0 24 24">
                  <path
                    fill="#6E63E7"
                    d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13 18H3V8L11 13L19 8V13H21V6C21 4.9 20.1 4 19 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H13V18M19 6L11 11L3 6H19Z"
                  />
                </svg>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <p>
                  Hola <strong>{{ this.email }}</strong
                  >, su cuenta ha sido activada con éxito
                </p>
                <p>Ahora puede iniciar sesión</p>
                <b-button :to="{ name: 'login' }">
                  <span class="form-text">CONTINUAR</span></b-button>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body
            id="ActivateFail"
            v-if="!loading && submitted && !successedActivation"
            class="pt-3"
          >
            <b-row>
              <b-col class="text-center text-secondary" variant="secondary">
                <svg style="width: 92px; height: 92px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M24 7H22V13H24V7M24 15H22V17H24V15M20 6C20 4.9 19.1 4 18 4H2C.9 4 0 4.9 0 6V18C0 19.1 .9 20 2 20H18C19.1 20 20 19.1 20 18V6M18 6L10 11L2 6H18M18 18H2V8L10 13L18 8V18Z"
                  />
                </svg>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <p>
                  Hola <strong>{{ this.email }}</strong
                  >, su cuenta no pudo ser activada, <br>
                  por favor intente nuevamente
                </p>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body
            id="ActivateInProgress"
            v-if="loading && !submitted"
            class="pt-3"
          >
            <b-row>
              <b-col class="text-center text-secondary" variant="secondary">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  variant="primary"
                  label="Cargando"
                ></b-spinner>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center mt-3">
                <p>
                  Hola <strong>{{ this.email }}</strong> estamos validando su
                  cuenta
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import constants from "@/constants"
import userService from "@/services/userService"
const WITHOUT_ERROR = constants.WITHOUT_ERROR_CODE

export default {
  name: "Activate",
  data: () => {
    return {
      loading: false,
      submitted: false,
      successedActivation: false,
      email: "",
      token: "",
    }
  },
  methods: {
    async activateUser() {
      try {
        this.loading = true
        const activateResponse = await userService.activateUser(
          this.email,
          this.token
        )
        if (activateResponse.data.error !== WITHOUT_ERROR) {
          this.submitted = true
          this.successedActivation = false
        } else {
          this.submitted = true
          this.successedActivation = true
        }
      } catch (error) {
        this.submitted = true
        this.successedActivation = false
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    this.token = this.$route.query.token
    this.email = this.$route.query.mail
    await this.activateUser()
  },
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}

.copyright {
  font-size: 0.8rem;
}

.mail-icon {
  color: #6e63e7 !important;
  font-size: 5rem !important;
}
.mail-icon-fail {
  color: #ff0066 !important;
  font-size: 5rem !important;
}
</style>
